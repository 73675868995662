import { CustomerModel } from "../models/customer.model";
import { UserModel } from "../models/user.modal";

export class UserAdapter {
  public static adapt(item: any): UserModel {
    return new UserModel(
      item.id,
      item.username,
      null,
      this.adaptCustomer(item.customer),
      item.isValid,
      item.dateAbonnement,
    )
  }

  public static adaptCustomer(item: any): CustomerModel {
    return new CustomerModel(
      item.id,
      item.firstname,
      item.lastname,
      item.email,
      item.sexe,
      item.image,
      item.phone,
      item.birth,
      item.compte,
    );
  }
}