import { Component } from '@angular/core';
import { Router, NavigationCancel, NavigationEnd } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { filter } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from './modules/front/services/auth/auth.service';
import { Store } from '@ngrx/store';
import { AuthActions } from './modules/authentification/store/auth.actions';
import { UserModel } from './modules/front/models/user.modal';
declare let $: any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class AppComponent {
    location: any;
    routerSubscription: any;

    constructor(
        private translateService: TranslateService, 
        private router: Router,
        private readonly authService: AuthService,    
        private readonly store: Store,
    ){
        this.translateService.setDefaultLang('fr');
        const lang = localStorage.getItem('language') || 'fr';
        this.translateService.use(lang);
        document.documentElement.lang = lang;
    }

    ngOnInit(){
        this.recallJsFuntions();
        this.loadCurrentUser();
    }

    recallJsFuntions() {
        this.routerSubscription = this.router.events
        .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
        .subscribe(event => {
            this.location = this.router.url;
            if (!(event instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0);
        });
    }

    public loadCurrentUser(): void {
        this.authService.loadCurrentUserProfile()
            ?.subscribe((user: UserModel) => {
                console.log('connected: ', user);
                this.store.dispatch(AuthActions.setCurrentUserProfile({ user }));
            });
            
    }
}
