import { createReducer, on } from '@ngrx/store';
import { UserModel } from '../../front/models/user.modal';
import { AuthActions } from './auth.actions';

export interface AuthState {
  currentUser: UserModel,
}

export const initialState: AuthState = {
  currentUser: null,
};

export const AuthReducer = createReducer(
  initialState,
  on(AuthActions.setCurrentUserProfile, (state: AuthState, action) => (
    { ...state, currentUser: action.user })
  ),
);