import { environment } from "src/environments/environment";
import { ChapterModel } from "../models/chapter.model";
import { CourseModel } from "../models/course.model";
import { LessonModel } from "../models/lesson.model";

export class CoursesAdapter {
  public static adapt(item): CourseModel {
    return new CourseModel(
      item.id,
      item.description,
      item.image,
      item.lecons,
      item.libelle,
      item.statut,
    );
  }

  public static  adaptChapter(item) {
    return new ChapterModel(
      item.id,
      item.libelle,
      item.description,
      item.module ? this.adapt(item.module) : null,
      item.statut,
      item.image,
    );
  }

  public static adaptLesson(item) {
    return new LessonModel(
      item.id,
      item.libelle,
      item.description,
      item.chapter ? this.adaptChapter(item.chapter) : null,
      item.statut,
      item.image
    )
  }
}