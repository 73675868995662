import { environment } from "src/environments/environment"

export class CourseModel {
  constructor(
    public id: number = null,
    public description: string = '',
    public image: string = '',
    public lecons: number = null,
    public libelle: string = '',
    public status: string = '',
  ) {}

  get imageUrl(): string {
    
    return `${environment.mediaUrl}/${this.image}`;
  }
}