import { ChapterModel } from "./chapter.model";

export class LessonModel {
  constructor(
    public id: number = null,
    public libelle: string = '',
    public description: string = '',
    public chapitre: ChapterModel | null = new ChapterModel(),
    public status: string = '',
    public image: string = ''
  ) {}
}