export class Compte {
  constructor(
    public id: number = null,
    public montant: number = null,
  ) {}
}

export class CustomerModel {
  constructor(
    public id: number = null,
    public firstname: string = '',
    public lastname: string = '',
    public email: string = '',
    public sexe: any = null,
    public image: string = '',
    public phone: string = '',
    public birth: string = '',
    public compte: Compte = new Compte(),
  ) {}
}