import { environment } from "src/environments/environment";

// Original configuration
const BASE = environment.apiUrl// 'http://192.168.8.101:';
const PORT = environment.apiPort; //'8080';

// const BASE = 'https://api.5nkap.org:8443/easycar';
// const PORT = '';

const API_PATH  = '/api/';
const PATH_COMPTE = 'compte';
const PATH_ROLE = 'role';
const PATH_DASHBOARD = 'dashboard';

export const API_URLS = {
    HOST_URL: BASE  + PORT,
    INIT_URL: BASE + PORT + '/init/shop',
    LOGIN_URL: BASE + PORT + '/login',
    COMPTE_URL: BASE + PORT + API_PATH + PATH_COMPTE,
    USER_URL: BASE + PORT + API_PATH + 'utilisateur',
    ROLE_URL: BASE + PORT + API_PATH + PATH_ROLE,
    PROJECT_URL: BASE + PORT + API_PATH + 'project',
    VISITEUR_URL: BASE + PORT + API_PATH + 'visiteur',
    MODULE_URL: BASE + PORT + API_PATH + 'module',
    CHAPITRE_URL: BASE + PORT + API_PATH + 'chapitre',
    CONSEIL_URL: BASE + PORT + API_PATH + 'conseil',
    LECON_URL: BASE + PORT + API_PATH + 'lecon',
};
