import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OnlyAuthenticatedUserGuard implements CanActivate {
  constructor(
    private readonly store: Store,
    private readonly router: Router,  
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const token = localStorage.getItem('access_token');

    if (!token) {
      this.router.navigateByUrl('/auth/login');
    }

    return !!token;
  }
  
}
