import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ErrorPageComponent } from './shared/components/error-page/error-page.component';

// import { LoadUserGuard } from './core/shared/helpers/load-user.guard';

const routes: Routes = [
    {path: '', pathMatch: 'full', redirectTo: 'new-version'},
    {path: 'auth', loadChildren: () => import('./modules/authentification/authentification.module').then(x => x.AuthentificationModule)},
    // {path: 'espace-clients', loadChildren: () => import('./components/pages/clients/clients.module').then(x => x.ClientsModule), canActivate: [LoadUserGuard]},
    // {path: 'comptes', loadChildren: () => import('./components/dashboard/dashboard.module').then(x => x.DashboardModule), canActivate: [LoadUserGuard]},
    
    {path: 'new-version', loadChildren: () => import('./modules/front/front.module').then((m) => m.FrontModule) },
    
    {path: 'error', component: ErrorPageComponent},
    // {path: '**', redirectTo: 'espace-clients/home'} // This line will remain down from the whole pages component list
    // {path: '**', redirectTo: 'new-version'} // This line will remain down from the whole pages component list
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
