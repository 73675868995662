import { CourseModel } from "./course.model";

export class ChapterModel {
  constructor(
    public id: number = null,
    public libelle: string = '',
    public description: string = '',
    public course: CourseModel = new CourseModel(),
    public status: string = '',
    public image: string = ''
  ) {}
}