import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CountUpModule } from 'ngx-countup';
import { TabsModule } from 'ngx-tabset';
import { NgxScrollTopModule } from 'ngx-scrolltop';
import { StickyNavModule } from 'ng2-sticky-nav';
import { LightboxModule } from 'ngx-lightbox';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AccordionModule } from "ngx-accordion";
import { LightgalleryModule } from 'lightgallery/angular';

import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { SimpleNotificationsModule } from 'angular2-notifications';
// import { CoreModule } from './core/core.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// import { AuthGuard } from './core/shared/helpers/auth.guard';
// import { AlertService } from './core/shared/services/alert.service';

import { RouterModule } from '@angular/router';
// import { SharedComponentsModule } from './shared-components/shared-components.module';

import { CommonModule } from '@angular/common';
import { AuthService } from './modules/front/services/auth/auth.service';
import { CoursesService } from './modules/front/services/courses/courses.service';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ErrorPageComponent } from './shared/components/error-page/error-page.component';
import { JwtInterceptor } from './modules/core/jwt.interceptor';
import { StoreModule } from '@ngrx/store';
import { AuthReducer } from './modules/authentification/store/auth.reducer';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from 'src/environments/environment';
import { OnlyAuthenticatedUserGuard } from './modules/core/guards/only-authenticated-user.guard';
import { OnlySubscribedUserGuard } from './modules/core/guards/only-subscribed-user.guard';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '../assets/i18n/', '.json');
}

let dev = environment.production ? [] : [StoreDevtoolsModule.instrument()];

@NgModule({
  declarations: [
    AppComponent,
    ErrorPageComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CarouselModule,
    CountUpModule,
    TabsModule,
    NgxScrollTopModule,
    StickyNavModule,
    LightboxModule,
    FormsModule,
    // TranslateModule,
    ReactiveFormsModule,
    AccordionModule,
    LightgalleryModule,
    HttpClientModule,
    // CoreModule,
    SimpleNotificationsModule.forRoot(),
    RouterModule,
    // SharedComponentsModule,

    // CoreModule
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
    }),
    StoreModule.forRoot({ auth: AuthReducer }),
    ...dev,
  ],
  providers: [
    // AlertService,
    // AuthGuard,
    AuthService,
    CoursesService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    OnlyAuthenticatedUserGuard,
    OnlySubscribedUserGuard,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
