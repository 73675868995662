import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { API_URLS } from 'src/app/config/app.url.config';
import { UserAdapter } from '../../adapters/user.adapter';
import { UserModel } from '../../models/user.modal';
import jwt_decode from 'jwt-decode';
import { Store } from '@ngrx/store';
import { AuthSelectors } from 'src/app/modules/authentification/store/auth.selectors';

@Injectable()
export class AuthService {
  public currentToken!: Observable<string>;
  public localLang!: Observable<string>;
  
  private localLangSubject!: BehaviorSubject<string>;
  private currentTokenSubject!: BehaviorSubject<string> ;

  constructor(
    private http: HttpClient,
    private readonly store: Store,
  ) {
    this.onInit();
  }

  onInit(): void {
    this.currentTokenSubject = new BehaviorSubject<string>(localStorage.getItem('language'));
    this.currentToken = this.currentTokenSubject.asObservable();
    this.localLangSubject = new BehaviorSubject<string>(localStorage.getItem('language') || 'fr');
    this.localLang = this.localLangSubject.asObservable();
  }

  public get localLangValue(): string {
    this.onInit();
    return this.localLangSubject.value;
  }

  public setLocalLangValue(lang: string): void {
    localStorage.setItem('language' , lang);
    this.localLangSubject.next(lang);
  }

  public login(login: any): Observable<any> {
    console.log('Login is called !!', login);
    
    return this.http.post(
          API_URLS.LOGIN_URL, 
          login,
          {observe: 'response'}
      )
    .pipe(
      //   share()
      map((response: any) => {
        if (response) {
         const token = response.headers.get('authorization');
         localStorage.setItem('access_token', token);
        }
      }
      )
     );
  }

  public getUserProfile(email: string): Observable<UserModel> {
    return this.http.get(`${API_URLS.USER_URL}/username/${email}`)
      .pipe(
        map((response: any) => {
          return UserAdapter.adapt(response.body);
        }),
      );
  }

  public isAuth(): boolean {
    return localStorage.getItem('access_token') != null;
  }

  public logout(): void {
    localStorage.removeItem('access_token');
  }

  public decodeAccessToken(): string {
    const token = localStorage.getItem('access_token');
    try {
      return jwt_decode(token);
    } catch(e) {
      return null;
    }
  }
  public loadCurrentUserProfile(): Observable<UserModel> | null{
    const token = this.decodeAccessToken();

    if (token) {
      const { sub } = this.decodeAccessToken();
      // @ts-ignore
      return this.getUserProfile(sub);
    }

    localStorage.removeItem('access_token');
    return null;
  }

  public isAbonned(): Observable<boolean | null> {
    return this.store.select(AuthSelectors.currentUser)
      .pipe(
        map((user: UserModel) => {
          if (!user) {
            return null;
          }

          return !!user?.id && user?.isValid;
        }),
      );
  }
}