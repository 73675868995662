import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthSelectors } from '../../authentification/store/auth.selectors';
import { UserModel } from '../../front/models/user.modal';

@Injectable({
  providedIn: 'root'
})
export class OnlySubscribedUserGuard implements CanActivate {
  constructor(
    private readonly store: Store,
    private readonly router: Router,  
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      return this.store.select(AuthSelectors.currentUser)
        .pipe(
          map((user: UserModel) => {
            console.log('guard subscribed user: ', user);

            if (user && user.isValid) {
                return true;               
            }
            
            this.router.navigateByUrl('/new-version/cart');
            return false;
          })
        );
  }
  
}
