import { CustomerModel } from "./customer.model";

export class UserModel {
  constructor(
    public id: number = null,
    public username: string = '',
    public password: string = '',
    public customer: CustomerModel = new CustomerModel(),
    public isValid: boolean = false,
    public dateAbonnement: Date = null,

  ) {}
}
