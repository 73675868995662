import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CoursesAdapter } from '../../adapters/courses.adapter';
import { ChapterModel } from '../../models/chapter.model';
import { CourseModel } from '../../models/course.model';
import { LessonModel } from '../../models/lesson.model';

@Injectable()
export class CoursesService {
  private url = 'https://api.5nkap.org:8443/easycar/api'; //'https://api.5nkap.org:8443/easycar/api/module/dto';

  constructor(
    private readonly http: HttpClient,
  ) {}

  public getAll(): Observable<CourseModel[]> {
    return this.http.get(`${this.url}/module/dto`)
      .pipe(
        map((response: any) => 
          response.body.map((c) => CoursesAdapter.adapt(c))
        ),
      )
  }

  public getById(id: number): Observable<CourseModel> {
    return this.http.get(`${this.url}/module/${id}`)
      .pipe(
        map((response: any) => {
          console.log('r', response);
          return CoursesAdapter.adapt(response.body);
        }),
      );
  }

  public getCourseChapters(courseId: number): Observable<ChapterModel[]> {
    return this.http.get(`${this.url}/chapitre/modules/${courseId}`)
      .pipe(
        map((response: any) => {
          console.log('chap: ', response);
          return response.body.map((chap) => CoursesAdapter.adaptChapter(chap));
        }),
      );
  }

  public getCourseLessons(lessonId: number): Observable<LessonModel[]> {
    return this.http.get(`${this.url}/lecon/chapitre/${lessonId}`)
      .pipe(
        map((response: any) => {
          console.log('Lessons: ', response);
          return response.body.map((lesson) => CoursesAdapter.adaptLesson(lesson));
        }),
      );
  }

  public getCourseLessonContent(lessonId: number): Observable<string> {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');

    return this.http.get(
      `${this.url}/lecon/text/${lessonId}`,
      { headers, responseType: 'text'} 
     )
      .pipe(
        map((response: any) => {
          console.log('Content: ', response);
          return response;
        }),
      );
  }
}